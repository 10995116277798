import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/felix/OneDrive/Documents/Bertram.Solutions/Website.TTcom/GatsbyJS-2022/src/components/mdx/component.js";
import { GenericPage } from "../../../components/mdx/page";
import { Breadcrumbs } from "../../../components/widgets/breadcrumbs";
import { Aside } from "../../../components/widgets/aside";
import { Border } from "../../../components/widgets/container";
import { ButtonPrimary, ButtonSecondary } from "../../../components/widgets/button";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = GenericPage;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h1>{props.pageContext.frontmatter.title}</h1>
    <Breadcrumbs text="Help" to="/help/" mdxType="Breadcrumbs" />
    <Aside mdxType="Aside">
      <p><strong parentName="p">{`See Also`}</strong></p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/help/getting-started/"
          }}>{`Getting Started with TuringTrader`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/help/find-your-portfolio/"
          }}>{`Selecting a Portfolio`}</a></li>
      </ul>
    </Aside>
    <p>{`Investing with `}<em parentName="p">{`TuringTrader.com`}</em>{` is easy. But your long-term success hinges on adopting some new habits. This article discusses the most important ones.`}</p>
    <h2>{`Stay on Schedule`}</h2>
    <p>{`When managing your assets, make it a habit to stick to the required schedule. A portfolio that rebalances monthly might tolerate the occasional slip by a day. A portfolio that rebalances daily will certainly not. Most importantly, you should never wholly skip a rebalancing cycle. `}</p>
    <p>{`Often staying on a tight schedule might not seem to matter too much, but there is the risk of missing that one critical update. In any case, once you deviate from the suggested schedule, you are on your own, and our backtests no longer apply.`}</p>
    <p>{`To make it easier for you to stay on schedule, `}<em parentName="p">{`TuringTrader.com`}</em>{` can send you email notifications. Our article on `}<a parentName="p" {...{
        "href": "/help/investment-dashboard/"
      }}>{`configuring your dashboard`}</a>{` explains how to set these up.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/c173a0ac86a4011dd92d271a0b07be69/4f4c5/stanislav-kondratiev-MdexOj4D-MU-unsplash.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAMCBf/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAHqoks0r//EABoQAAICAwAAAAAAAAAAAAAAAAECEiEAEBH/2gAIAQEAAQUCagLERjmAR+6//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFRABAQAAAAAAAAAAAAAAAAAAESD/2gAIAQEABj8Ca//EABkQAQADAQEAAAAAAAAAAAAAAAEAESExUf/aAAgBAQABPyEX0upYVKU5AiLjp4xups//2gAMAwEAAgADAAAAED/f/8QAFhEBAQEAAAAAAAAAAAAAAAAAABFB/9oACAEDAQE/EJqP/8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQIBAT8QV//EABwQAQEBAAIDAQAAAAAAAAAAAAERACFxMUFRYf/aAAgBAQABPxADmxYcYxxC/H5piLpm/BS8b2R7cvCIdm//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "data-driven portfolios",
            "title": "data-driven portfolios",
            "src": "/static/c173a0ac86a4011dd92d271a0b07be69/e5166/stanislav-kondratiev-MdexOj4D-MU-unsplash.jpg",
            "srcSet": ["/static/c173a0ac86a4011dd92d271a0b07be69/f93b5/stanislav-kondratiev-MdexOj4D-MU-unsplash.jpg 300w", "/static/c173a0ac86a4011dd92d271a0b07be69/b4294/stanislav-kondratiev-MdexOj4D-MU-unsplash.jpg 600w", "/static/c173a0ac86a4011dd92d271a0b07be69/e5166/stanislav-kondratiev-MdexOj4D-MU-unsplash.jpg 1200w", "/static/c173a0ac86a4011dd92d271a0b07be69/d9c39/stanislav-kondratiev-MdexOj4D-MU-unsplash.jpg 1800w", "/static/c173a0ac86a4011dd92d271a0b07be69/df51d/stanislav-kondratiev-MdexOj4D-MU-unsplash.jpg 2400w", "/static/c173a0ac86a4011dd92d271a0b07be69/4f4c5/stanislav-kondratiev-MdexOj4D-MU-unsplash.jpg 4848w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2>{`Stay the Course`}</h2>
    <p>{`You probably put some time and thought into choosing a portfolio that matches your investment goals. You weighed risks and returns and matched these with your personal preferences.`}</p>
    <p>{`Keep in mind that investing is a marathon, not a sprint. There will be times when markets take a turn, and the performance is not where we like it to be. In these moments, it is crucial to stay the course. We designed the model portfolios to handle similar historical situations gracefully, and you should put some faith into that. That's why you favored a data-driven approach in the first place. Don't be tempted to second-guess the asset allocation. Don't stop rebalancing or investing altogether. Stay the course!`}</p>
    <p>{`To learn more about the reasons why you shouldn't de-risk your investments after a drawdown, read our article on `}<a parentName="p" {...{
        "href": "/help/find-your-portfolio/"
      }}>{`selecting a portfolio`}</a>{`.`}</p>
    <h2>{`Use Common Sense`}</h2>
    <p>{`Especially if you are new to `}<em parentName="p">{`TuringTrader.com`}</em>{`, you might be tempted to take the asset allocations too literally. While it is true that you need to follow the asset allocations closely to achieve results that track our backtests, that does not mean that you should go overboard with this. Because many brokerages charge a minimum fee of $1 per trade, orders are often too small to justify that expense. To learn more about rebalancing, we recommend reading our article on `}<a parentName="p" {...{
        "href": "/help/account-rebalancing/"
      }}>{`portfolio rebalancing`}</a>{`.`}</p>
    <p>{`The same is true on the other end of the spectrum: We strongly advise against taking too many liberties when tracking our portfolios. You most likely came to `}<em parentName="p">{`TuringTrader.com`}</em>{` because you see value in our software-centric analysis. Unfortunately, second-guessing our portfolio algorithms is a slippery slope, which quickly brings you back to discretionary trading. `}</p>
    <h2>{`Start Today`}</h2>
    <p>{`The most significant factor to successful investments is the gift of time. Don't wait until a few years before retirement. Start early. We hope that `}<em parentName="p">{`TuringTrader.com`}</em>{` will be part of your personal success story.`}</p>
    <ButtonPrimary to="/portfolios/" text="explore our portfolios" mdxType="ButtonPrimary" />
    <br />
    <br />
    <br />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      